// i18next-extract-mark-ns-start pci-dss

import {Article} from 'components/Article';
import {SEO} from 'components/SEO';
import {graphql, PageProps} from 'gatsby';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';
import React from 'react';

const Page: React.FC = () => {
  const {t} = useI18next();
  return (
    <Article wide>
      <SEO
        path="pci-dss"
        title={t('PCI DSS')}
        description={t(
          'MONEI is a PCI DSS Level 1 certified compliant Service Provider organization.'
        )}
      />
      <Trans parent="h1">PCI DSS</Trans>
      <Trans parent="p">
        MONEI is a <strong>PCI DSS Level 1</strong> certified compliant Service Provider
        organization.{' '}
        <a rel="noopener noreferrer" href="https://monei.com/blog/understanding-pci-compliance/">
          PCI DSS
        </a>{' '}
        is a comprehensive set of requirements created by the Payment Card Industry Security
        Standards Council to enhance cardholder data security and to ensure the safe handling and
        storage of sensitive customer credit card information and data. Maintaining the security of
        cardholder data is very important to MONEI. MONEI's PCI DSS responsibilities as a Service
        Provider are outlined in the Attestation of Compliance (AOC) as independently audited by
        MONEI’s Qualified Security Assessor (QSA). MONEI's Attestation of Compliance (AOC) is
        submitted to MONEI’s acquiring bank(s).
      </Trans>
      <Trans parent="p">
        For further information please visit the official PCI org website{' '}
        <a rel="noopener noreferrer" href="https://www.pcisecuritystandards.org/">
          www.pcisecuritystandards.org
        </a>
        .
      </Trans>
      <Trans parent="h3">Consumer</Trans>
      <Trans parent="p">
        Upon Consumer agreement to MONEI Terms, MONEI secures and protects the cardholder data
        according to the currently applicable PCI standard for the life of the data needing to be
        retained. MONEI acknowledges these responsibilities as being the organization responsible
        for ensuring the safe handling and storage of sensitive customer credit card information and
        data for the MONEI services.
      </Trans>
      <Trans parent="h3">Merchant</Trans>
      <Trans parent="p">
        MONEI merchants must implement MONEI technologies according to MONEI approved configuration.
        MONEI merchants have effectively delegated their PCI DSS responsibilities for sensitive
        customer credit card information and data collected through the MONEI Merchant Agreement
        process and Customer Agreement. Merchant’s may have other PCI DSS responsibilities that are
        independent of the MONEI Merchant Agreement process. It is the Merchant’s sole
        responsibility to remain informed of their PCI obligations and compliance status.
      </Trans>
      <Trans parent="p">
        Merchant’s should always consult their own Information Security professionals to review the
        security of the merchant’s business where required. A Qualified Security Assessor should be
        consulted if the merchant manages other sensitive customer credit card information and data
        or the merchant’s implementation of MONEI technologies has deviated from the approved
        configuration.
      </Trans>
      <Trans parent="p"> MONEI Attestation of Compliance (AOC) is available on request.</Trans>
      <Trans parent="p">
        MONEI Privacy Policy is available{' '}
        <Link rel="noopener noreferrer" to="/privacy-policy/">
          here
        </Link>
        .
      </Trans>
    </Article>
  );
};

export default Page;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "pci-dss"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
